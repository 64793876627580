dialog {
    /* width: 400px; */
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;

    /* margin: 10rem auto; */
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 100;

    background-image: url("/public/ui/popup1.jpg");

    color: #473737;
    font-family: Droid Serif, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

}

dialog::backdrop {
    background: rgba(105, 100, 75, 0.7)
}
