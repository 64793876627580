
.button_main {
    color: #7a4747;
    /* border: 2px solid #000;
    border-radius: 10px; */
    width: 64px;
    height: 64px;
    font-size: 12px;
    font-weight: 400;
    box-sizing: border-box;

    /* flex: 1; Чтобы кнопки занимали одинаковую ширину */
}

.button_main_img {
    vertical-align: middle;
    max-width: 100%;
    display: inline-block;
    cursor: pointer;
  
}

.fixed-footer {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;

    padding-bottom: 2%;

    width: 90%;
    gap: 2%; /* Добавляем отступы между кнопками */

    z-index: 66; /* Base z-index */


  }