.grid_main_menu {
    grid-column-gap: 18px;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 4fr .25fr;
    /* margin-top: 5%; */
    margin-left: 5%;
    margin-right: 5%;
    font-family: Droid Serif, serif;

    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}


.section_main {
    grid-area: 1 / 1 / 9 / 2;
    box-sizing: border-box;
    /* width: 256px; */
    width: 100%;
    
}

.section_image {
    display: block;
    height: 180px;
    width: 100%;
}


.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* min-height: 100vh; */

    /* margin-left: 5%;
    margin-right: 5%;*/
    font-family: Droid Serif, serif; 

  }
  
  .scrollable-list {
    flex-grow: 1; /*Чтобы занимать оставшееся пространство*/
    overflow-y: auto; /* Включает вертикальный скролл */
    padding: 10px;
  }
  
  

  