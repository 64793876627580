.market_button {
    background-color: transparent;
    /* Remove default button styling */
    border: none;
    cursor: pointer;
}

.market_button img {
    width: auto;
    /* Set desired width */
    height: 32px;
}