.head_resources_section {
    padding-bottom: 5px;
    margin-left: 5%;
    margin-right: 5%;
    height: 40px;

}


.head_resources {

    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, 50px);
    grid-template-rows: repeat(2, 20px);
    grid-column-gap: 15px;
    grid-row-gap: 0px;
}

.dooblons {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    gap: 2px;
}

.citizen {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    gap: 2px;
}

.slave {
    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    gap: 2px;
}

.food {
    grid-area: 1 / 4 / 2 / 5;
    display: flex;
    gap: 2px;
}

.wood {
    grid-area: 1 / 5 / 2 / 6;
    display: flex;
    gap: 2px;
}

.island_name {
    grid-area: 2 / 1 / 3 / 3;
    display: flex;
    gap: 2px;
}

.metal {
    grid-area: 2 / 3 / 3 / 4;
    display: flex;
    gap: 2px;
}

.gunpowder {
    grid-area: 2 / 4 / 3 / 5;
    display: flex;
    gap: 2px;
}

.sailcloth {
    grid-area: 2 / 5 / 3 / 6;
    display: flex;
    gap: 2px;
}


.res_image {
    width: 20px;
    height: 20px;
    size: 20px;
    position: relative;
}
