body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url("/public/ui/background-main.jpg");
  background-position: 100% 100%;
  background-size: cover;
  height: 100vh;
  /* background-repeat: repeat-y;  */

  
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;

  overflow-y: scroll;

  border-left: 1px solid #eecc0a;
  border-right: 1px solid #eecc0a;
  border-top: 1px solid #eecc0a;

  /* Запретилить выделение текста */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
          not supported by any browser */

          
}


.text {
  color: #473737;
  font-family: Droid Serif, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-wrap;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

