/* Continue */

.button_continue {
    opacity: 0.75;
    color: #4e0c0c;
    background-image: url("/public/ui/buttons/button_1.jpg");
    background-position: 100% 100%;
    background-repeat: repeat;
    background-size: cover;
    background-attachment: scroll;
    border: 1px solid #8d9269;
    border-radius: 5px;
    align-self: flex-end;
    font-family: Droid Serif, serif;
    font-weight: 700;
    text-decoration: none;
    position: relative;

    height: 40px;
    width: 120px;
  
    line-height: inherit;
    cursor: pointer;
    padding: 9px 15px;
    display: inline-block;
  
  }
.button_continue:hover {
  opacity: 1.0;

}

.button_continue_active {
  background:#e6e6e6;
  color:#e2ff09;
}

.button_continue:disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

/* Close */

.button_close {
  opacity: 0.75;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  margin-left:90%; 
}

.img_close{
  width: 32px;
  height: 32px;
}

.button_close:hover {
opacity: 1.0;

}

.button_close:disabled {
cursor: not-allowed;
opacity: 0.1;
}



/* Build */
.button_build {
  opacity: 0.75;
  color: #4e0c0c;
  background-image: url("/public/ui/buttons/button_1.jpg");
  background-position: 100% 100%;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: scroll;
  border: 1px solid #8d9269;
  border-radius: 5px;
  align-self: flex-end;
  font-family: Droid Serif, serif;
  font-weight: 700;
  text-decoration: none;
  position: relative;

  margin-top: 10px;


  height: 40px;
  width: 120px;

  line-height: inherit;
  cursor: pointer;
  padding: 9px 15px;
  display: inline-block;

}
.button_build:hover {
opacity: 1.0;

}

.button_build_active {
background:#e6e6e6;
color:#e2ff09;
}

.button_build:disabled {
cursor: not-allowed;
opacity: 0.1;
}


/* Save */
.button_save {
  opacity: 0.75;
  color: #4e0c0c;
  background-image: url("/public/ui/buttons/button_1.jpg");
  background-position: 100% 100%;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: scroll;
  border: 1px solid #8d9269;
  border-radius: 5px;
  align-self: flex-end;
  font-family: Droid Serif, serif;
  font-weight: 700;
  text-decoration: none;
  position: relative;

  margin-top: 10px;


  height: 40px;
  width: 120px;

  line-height: inherit;
  cursor: pointer;
  padding: 9px 15px;
  display: inline-block;

}
.button_save:hover {
opacity: 1.0;

}

.button_save_active {
background:#e6e6e6;
color:#e2ff09;
}

.button_save:disabled {
cursor: not-allowed;
opacity: 0.1;
}



/* Assign */
.button_assign {

  height: 32px;
  width: 32px;
  vertical-align: middle;
  max-width: 100%;
  cursor: pointer;
  margin-left: 10px;
  
}

