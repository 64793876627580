.registration_body {
  
    background-image: url("/public/ui/regist_background.jpg");
    /* background-position: 100% 100%; */
    background-size: cover;
    height: 100vh;
    background-repeat: no-repeat; 


    background-position: 0 0;
    background-size: cover;
    background-attachment: fixed;
    background-clip: border-box;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: visible;

    overflow-y: scroll;
  }

.registration_form {
  background-color: #aba887;
  border: 1px solid #9b8102;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;

  max-width: 940px;
  display: block;
  
}


.registration_label {
  font-family: Droid Serif, serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
  display: block;
}

.registration_input {
  background-color: #ab9c6c;
  border-radius: 5px;
  width: 30%;
  font-family: Droid Serif, serif;

  margin-top: 5px;
  margin-bottom: 5px;


  color: #333;
  border: 1px solid #ac780a;
  /* width: 100%; */
  height: 30px;
  padding: 8px 8px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.button_flag {
  border: 2px dashed #99810c;
  border-radius: 5px;
  width: 64px;
  height: 40px;
  margin: 5px;
  position: relative;

  max-width: 100%;
  display: inline-block;

  text-decoration: none;
  cursor: pointer;

  vertical-align: middle;

}

.button_flag_16 {
  border: 1px dashed #99810c;
  border-radius: 5px;
  width: 32px;
  height: 22px;
  margin: 5px;
  position: relative;

  max-width: 100%;
  display: inline-block;

  text-decoration: none;

  vertical-align: middle;

}