.build_field {
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 10%;
    cursor: pointer;

    opacity: 1.0;
}

.build_field_image {
    display: block;
    height: 180px;
    /* width: 254px; */
    width: 100%;

    /* border-radius: 10px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    opacity: 1.0;
}

.build_field_text {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;

    opacity: 1.0;

}


/* HOVER:::: */

.build_field_hover {
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 10%;
    cursor: pointer;

    opacity: 0.5;
    /* background: #6f7e42; Цвет фона */
}

.build_field_image_hover {
    display: block;
    height: 180px;
    width: 100%;
    /* border-radius: 10px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    opacity: 0.5;
}

.build_field_text_hover {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;

    opacity: 0.5;
}

/* !!!!!!!!!! */

.build_field_info {
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 10%;

    opacity: 1.0;
}

.build_cost {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 5%;

    display: flex;
    justify-content: center;
    align-items: center; 
    
}