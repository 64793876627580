.market_grid {
    display: grid;
    grid-template-columns: repeat(2, 64px);
    grid-template-rows: 128px repeat(2, 40px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

        /* Centering the entire grid in its container */
        justify-content: center;
        align-content: center;
}

.market_product {
    grid-area: 1 / 1 / 2 / 3;
}
.market_product img {
    width: 128px;
    height: 128px;
    object-fit: cover; /* Optional: This ensures the image is not distorted */
}

.market_buy_rate {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    justify-content: left;
    align-content: left;
    color: rgba(28, 172, 24, 0.856);

}

.market_sell_rate {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    justify-content: right;
    align-content: right;
    color: rgba(185, 26, 26, 0.856);

}

.market_buy {
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    justify-content: left;
    align-content: left;
}

.market_sell {
    grid-area: 3 / 2 / 4 / 3;
    display: flex;
    justify-content: right;
    align-content: right;
}










.swiper_parent {
    margin-top: 10%;
    display: grid;
    grid-template-columns: 5% 80% 5%;
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;


    align-items: center; 
    justify-content: center;
}

.swiper_content {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Даем возможность контенту занимать нужное пространство */
}

.swiper_arrow_left {
    grid-area: 1 / 1 / 2 / 2;
    /*display: flex;
    align-items: center; /* Центрирование по вертикали */
    /*justify-content: center; /* Если нужно центрирование по горизонтали */
    /*height: 100%; /* Задайте высоту родителя, например, 100%, если это важно */
}

.swiper_arrow_right {
    grid-area: 1 / 3 / 2 / 4;
    /*display: flex;
    align-items: center; /* Центрирование по вертикали */
    /*justify-content: center; /* Если нужно центрирование по горизонтали */
    /*height: 100%; /* Задайте высоту родителя, например, 100%, если это важно */
}






button.arrow {
    background: none;    /* Remove default button background */
    border: none;        /* Remove default button border */
    cursor: pointer;     /* Keep pointer style for clickable effect */
}


.market_exchange_price {
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: space-between; /* Count слева, Price справа */
    width: 100%; /* Занимаем всю ширину родительского контейнера */
}
.res_image {
    margin-left: 5px; /* Добавляем отступ для картинки */
}