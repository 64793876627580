/* Build */
.button_exchange {
    opacity: 0.75;
    color: #4e0c0c;
    background-image: url("/public/ui/buttons/button_1.jpg");
    background-position: 100% 100%;
    background-repeat: repeat;
    background-size: cover;
    background-attachment: scroll;
    border: 1px solid #8d9269;
    border-radius: 5px;
    align-self: flex-end;
    font-family: Droid Serif, serif;
    font-weight: 700;
    text-decoration: none;
    position: relative;
  
    margin-top: 10px;
  
  
    height: 40px;
    width: 120px;
  
    line-height: inherit;
    cursor: pointer;
    padding: 9px 15px;
    display: inline-block;
  
  }
  .button_exchange:hover {
  opacity: 1.0;
  
  }
  
  .button_exchange_active {
  background:#e6e6e6;
  color:#e2ff09;
  }
  
  .button_exchange:disabled {
  cursor: not-allowed;
  opacity: 0.1;
  }



  .container_button_exchange {
    display: flex;
    justify-content: center;  /* Центрирование по горизонтали */
    align-items: center;      /* Центрирование по вертикали */
}